<template>
  <div class="notify-panel">
    <div
      class="progress"
      :class="{
        error: notifyState.progress.error,
      }"
      :hidden="!notifyState.progress.visible"
    >
      <div
        class="progress-bar"
        :style="{
          width: notifyState.progress.percent,
        }"
      >
        {{ notifyState.progress.message }}
      </div>
    </div>

    <div
      class="toast"
      :class="{
        error: notifyState.toast.error,
      }"
      :hidden="!notifyState.toast.visible"
    >
      <div class="toast-bar">
        {{ notifyState.toast.message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { notifyState, wrapUpTimeSeconds } from "./notifyState";
const wrapUpTime = computed(() => `${wrapUpTimeSeconds}s`);
</script>

<style scoped>
.notify-panel {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.toast,
.progress {
  position: absolute;
  width: calc(100% - 4px);
  top: 0;
  height: 26px;
  overflow: hidden;
  padding: 4px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
  transition:
    /* width 0.8s ease-in-out, */
    opacity v-bind(wrapUpTime) ease-in-out,
    top v-bind(wrapUpTime) ease-in-out;
  opacity: 1;
  margin: 2px;
}

.toast[hidden],
.progress[hidden] {
  opacity: 0.3;
  display: block !important;
  top: -26px;
}

.toast-bar,
.progress-bar {
  height: 16px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));

  /*  transition:
    background-color 0.2s ease-in,
    width 0.8s ease-in;*/
  box-shadow:
    0 0 1px 1px rgba(0, 0, 0, 0.25),
    inset 0 1px rgba(255, 255, 255, 0.1);

  width: 100%;
  background-color: #27e;

  font-size: 0.8em;
  color: #000;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);

  line-height: 1.1;
  text-align: left;
  padding-left: 4px;
  white-space: nowrap;
}

.progress-bar {
  animation: progress-shimmer 1s linear infinite;
  background-image: repeating-linear-gradient(
      40deg,
      transparent,
      transparent 5%,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.2) 30%,
      transparent 45%,
      transparent 50%
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-size: 200% 100%;
}
.toast.error > .toast-bar,
.progress.error > .progress-bar {
  background-color: #e20;
}

@keyframes progress-shimmer {
  from {
    background-position: right;
  }
  to {
    background-position: left;
  }
}
</style>
